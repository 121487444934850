<template>
  <!-- 全明健身发展指数 -->
  <div class="container">
    <!-- 第一层按钮组 -->
    <div id="btnGroup" class="btnGroup">
      <div :class="['btn',index == currentIndex ? 'btnFocus' :'']" v-for="(item,index) in btns" :key="index" @click="btnClick(index,item.id)">
        {{item.title}}
      </div>
    </div>
    <!-- 第二层面积数据及图表 -->
    <div class="rows darkBack">
      <!-- 左侧数据内容 -->
      <div class="dataOne">
        <div class="dataOneItem" v-for="(item,index) in areaData" :key="index">
          <img class="dataOneImg" :src="item.img">
          <div>
            <div class="lightGray">{{item.title}}</div>
            <div class="greenFont">{{item.num}}</div>
          </div>
        </div>
      </div>
      <!-- 右侧三个图表 -->
      <div class="chartOne">
        <!-- 第一张 -->
        <div class="chartOneItem" v-for="(item,index) in areaPie" :key="index">
          <p>{{item.data.gender}}</p>
          <div :id="item.id"></div>
        </div>
        <!-- 第二张 -->
        <!-- <div class="chartOneItem">
          <p>学校体育场地设施</p>
          <p>开放率</p>
          <div id="schoolOpen"></div>
        </div> -->
        <!-- 第三张 -->
        <!-- <div class="chartOneItem">
          <p>"参加登记或备案的体育社会"</p>
          <p>组织人数占总人口比例</p>
          <div id="numberOf"></div>
        </div> -->
      </div>
    </div>
    <!-- 第三层数据及图表 -->
    <div class="rows darkBack">
      <!-- 左侧数据部分 -->
      <div class="dataTwo">
        <div class="dataTwoItem" v-for="(item,index) in numData" :key="index">
          <img class="dataTwoImg" :src="item.img" >
          <p class="lightGray">{{item.title1}}</p>
          <p class="lightGray">{{item.title2}}</p>
          <p class="font22 blueFont marginTop10">{{item.num}}</p>
        </div>
      </div>
      <!-- 右侧图表 -->
      <div class="chartTwo">
        <div class="chartTwoItem" v-for="(item,index) in numChart" :key="index">
          <div :id="item.id"></div>
          <span class="lightGray">{{item.data.gender}}</span>
        </div>
      </div>
    </div>
    <!-- 第四层图表 -->
    <div class="rows darkBack">
      <!-- 左侧数据部分 -->
      <div class="dataThree" v-show="satisfactionData.title">
        <img class="dataThreeImg" :src="satisfactionData.img" >
        <p class="lightGray">{{satisfactionData.title}}</p>
        <p class="font22 yellowFont marginTop10">{{satisfactionData.num}}</p>
      </div>
      <!-- 右侧图表 -->
      <div class="chartThree" v-show="healthChart">
        <div class="chartThreeItem lightGray" v-for="(item,index) in healthChart" :key="index">
          <p>{{item.data.gender}}</p>
          <!-- <p>{{item.data.gender2}}</p> -->
          <div :id="item.id"></div>
        </div>
      </div>
    </div>
    <div v-show="toastShow" class="mask">
      <div class="toast">该区域暂无数据</div>
    </div>
  </div>
</template>

<script>
import {getAjax, timestamp} from "/src/assets/js/websocket";

// import Toast from '../../toast';
export default {
  // components:{
  //   Toast
  // },
  data() {
    return{
      pageData:"",
      toastShow:false,
      currentIndex:0,
      btns:[
        {
          title:'绍兴市',
          id:330600
        },{
          title:'越城区',
          id:330602
        },{
          title:'新昌县',
          id:330624
        },{
          title:'诸暨市',
          id:330681
        },{
          title:'柯桥区',
          id:330621
        },{
          title:'上虞区',
          id:330682
        },{
          title:'嵊州市',
          id:330683
        },
      ],

      areaData:[
        {
          title:"人均体育场地面积",
          num:"2.51平方米",
          img:require('../../../assets/images/area1.png'),
        },
        {
          title:"人均公共体育场地面积",
          num:"1.36平方米",
          img:require('../../../assets/images/area2.png'),
        },
        {
          title:"人均室内体育场地面积",
          num:"0.19平方米",
          img:require('../../../assets/images/area3.png'),
        },
        {
          title:"人均体育事业经费",
          num:"344.21元",
          img:require('../../../assets/images/area4.png'),
        },
        {
          title:"每万人拥有健身步道公里数",
          num:"1.76公里",
          img:require('../../../assets/images/area5.png'),
        },
        {
          title:`每万人"三大球"及网球场地`,
          num:"11.7片",
          img:require('../../../assets/images/area6.png'),
        },
        {
          title:"冰雪运动场地数",
          num:"1片",
          img:require('../../../assets/images/area7.png'),
        },
        {
          title:"人均体育消费",
          num:"74.21元",
          img:require('../../../assets/images/area8.png'),
        },
      ],

      areaPie:[
        {
          id:"twoChart1",
          data:{
            gender: `"一场两馆"覆盖率`,
            color1: '#42BF6C', //环形图数据达标部分颜色
            color2: '#333453', //环形图背景色
            color3: '#42BF6C', //环形图中间字体颜色
            value: '55',  
          }
        },{
          id:"twoChart2",
          data:{
            gender: `学校体育场地设施开放率`,
            color1: '#42BF6C', //环形图数据达标部分颜色
            color2: '#333453', //环形图背景色
            color3: '#42BF6C', //环形图中间字体颜色
            value: '89',
          }
        },{
          id:"twoChart3",
          data:{
            gender: `参加登记或备案的体育社会组织人数占总人口比例`,
            color1: '#42BF6C', //环形图数据达标部分颜色
            color2: '#333453', //环形图背景色
            color3: '#42BF6C', //环形图中间字体颜色
            value: '28',
          }
        }
      ],


      // 第三层数量行数据
      numData:[
        {
          title1:"每万人拥有",
          title2:"体育社会组织数",
          num:"0.36个",
          img:require('../../../assets/images/num1.png'),
        },
        {
          title1:"每千人拥有注册",
          title2:"社会体育指导员人数",
          num:"2.78个",
          img:require('../../../assets/images/num2.png'),
        },
      ],

      numChart:[
        {
          id:'numChart1',
          data:{
            gender: `经常参加体育锻炼的人数`,
            color1: '#6AD4F9', //环形图数据达标部分颜色
            color2: '#333453', //环形图背景色
            color3: '#6AD4F9', //环形图中间字体颜色
            value: '28.3',
          }
        },
        {
          id:'numChart2',
          data:{
            gender: `每周参加一次及以上体育锻炼`,
            color1: '#6AD4F9', //环形图数据达标部分颜色
            color2: '#333453', //环形图背景色
            color3: '#6AD4F9', //环形图中间字体颜色
            value: '52.6',
          }
        },
        {
          id:'numChart3',
          data:{
            gender: `参加体育赛事活动的人数比例`,
            color1: '#6AD4F9', //环形图数据达标部分颜色
            color2: '#333453', //环形图背景色
            color3: '#6AD4F9', //环形图中间字体颜色
            value: '73.33',
          }
        },
        {
          id:'numChart4',
          data:{
            gender: `参加体育社会组织的人群每年`,
            color1: '#6AD4F9', //环形图数据达标部分颜色
            color2: '#333453', //环形图背景色
            color3: '#6AD4F9', //环形图中间字体颜色
            value: '73.33',
          }
        },
        {
          id:'numChart5',
          data:{
            gender: `体育场地纳入省体育公共服务平台率`,
            color1: '#6AD4F9', //环形图数据达标部分颜色
            color2: '#333453', //环形图背景色
            color3: '#6AD4F9', //环形图中间字体颜色
            value: '73.33',
          }
        },
        {
          id:'numChart6',
          data:{
            gender: `省体育公共服务平台"周活跃用户"数占总人口比例`,
            color1: '#6AD4F9', //环形图数据达标部分颜色
            color2: '#333453', //环形图背景色
            color3: '#6AD4F9', //环形图中间字体颜色
            value: '73.33',
          }
        },
        {
          id:'numChart7',
          data:{
            gender: `"智慧化"体育场馆数量比`,
            color1: '#6AD4F9', //环形图数据达标部分颜色
            color2: '#333453', //环形图背景色
            color3: '#6AD4F9', //环形图中间字体颜色
            value: '73.33',
          }
        },
        {
          id:'numChart8',
          data:{
            gender: `社会体育指导员上岗智慧化管理比例`,
            color1: '#6AD4F9', //环形图数据达标部分颜色
            color2: '#333453', //环形图背景色
            color3: '#6AD4F9', //环形图中间字体颜色
            value: '73.33',
          }
        },
      ],


      // 第四层满意度数据
      satisfactionData:{
        title:"全民健身综合满意度",
        num:"81.3",
        img:require('../../../assets/images/satisfaction.png'),
      },


      healthChart:[
        {
          id:'health1',
          data:{
            gender: `科学健身知识知晓度`,
            color1: '#EED02B', //环形图数据达标部分颜色
            color2: '#333453', //环形图背景色
            color3: '#EED02B', //环形图中间字体颜色
            value: '27.5',
          }
        },
        {
          id:'health2',
          data:{
            gender: `国民体质监测合格率`,
            color1: '#EED02B', //环形图数据达标部分颜色
            color2: '#333453', //环形图背景色
            color3: '#EED02B', //环形图中间字体颜色
            value: '93.4',
          }
        },
        {
          id:'health3',
          data:{
            gender: `《国家体育锻炼标准》优秀率`,
            color1: '#EED02B', //环形图数据达标部分颜色
            color2: '#333453', //环形图背景色
            color3: '#EED02B', //环形图中间字体颜色
            value: '67.4',
          }
        },
        {
          id:'health4',
          data:{
            gender: `中小学生《国家学生体质健康标准》合格率`,
            color1: '#EED02B', //环形图数据达标部分颜色
            color2: '#333453', //环形图背景色
            color3: '#EED02B', //环形图中间字体颜色
            value: '96.79',
          }
        },
        {
          id:'health5',
          data:{
            gender: `中小学生近视率比上一年下降率`,
            color1: '#EED02B', //环形图数据达标部分颜色
            color2: '#333453', //环形图背景色
            color3: '#EED02B', //环形图中间字体颜色
            value: '73.33',
          }
        },
        {
          id:'health6',
          data:{
            gender: `青年学生(14-18周岁)肥胖率`,
            color1: '#EED02B', //环形图数据达标部分颜色
            color2: '#333453', //环形图背景色
            color3: '#EED02B', //环形图中间字体颜色
            value: '11.25',
          }
        },
      ]
    }
  },
  methods:{
    // 绘制环形图
    pie(id, number,radius,fontSize) {
      let that = this;
      // 基于准备好的dom，初始化echarts实例
      // var runningWater = that.$echarts.init(document.getElementById(id));
      let runningWater = this.$echarts.getInstanceByDom(document.getElementById(id))
      if (runningWater == null) { // 如果不存在，就进行初始化
        runningWater = this.$echarts.init(document.getElementById(id));
      }

      // 指定图表的配置项和数据
      var waterOption = {
        // backgroundColor: '#F7F7F7',
        //全局颜色，顺序对应每个 series
        color: [number.color1, number.color2],
        series: [
          {
            name: "中间环形",
            type: "pie",
            //圆，半径，值1内圆，值2外圆
            radius: radius,
            //饼图的中心（圆心）坐标，数组的第一项是横坐标，第二项是纵坐标
            center: ["50%", "46%"],
            avoidLabelOverlap: false,
            hoverAnimation: false,
            //数据标签
            label: {
              show: false,
              position: "center",
              textStyle: {
                fontSize: fontSize,
                fontWeight: "bold",
                color: "#fcf1cf", // 中间字体的颜色
              },
              formatter: "{c}%",
              // 针对 center 参数校正标签显示位置
              lineHeight: 3,
            },
            data: [
              {
                value: number.value,
                name: "",
                label: {
                  normal: {
                    show: true,
                  },
                },
              },
              {
                value: 100 - number.value,
                name: "",
              },
            ],
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      runningWater.setOption(waterOption,true);

      // 图表自动缩放
      window.addEventListener("resize", function () {
        runningWater.resize();
      });
    },
    // 按钮点击事件
    btnClick(index,id){
      let that = this
      that.currentIndex = index
      // 给点击的按钮添加样式
      // $(event.target).addClass("btnFocus")
      // 将当前点击按钮的兄弟元素的选中样式删除
      // $(event.target).siblings().removeClass("btnFocus");
      // if(event.target.innerText == '绍兴市'){
      //   this.getPageData('市',id);
      //   this.getPageData('市',id);
      // }else{
      //   this.getPageData('区',id);
      //   this.getPageData('区',id);
      // }
      this.dataDraw(event.target.innerText)
      
    },
    // 获取页面数据
    getPageData(){
      let that = this;

      let data = {
        token: window.getToken(),
        app_id: window.appId,
        time: timestamp(),
      }
      let url = window.appHost+"api/v1/screen/fitness_Index/index"
      let res = window.ajaxRequest(url,JSON.stringify(data))
      // console.log(res)
      that.pageData = res.data;
      that.dataDraw("绍兴市")
      
      // getAjax({
			// 		url: "api/v1/screen/fitness_Index/index?years="+2021,
			// 		method:"GET",
			// 		data:data,
			// 		success:function(res){
      //       // console.log(res)
      //       that.pageData = res.data;
      //       that.dataDraw("绍兴市")
      //     }
      // })
    },
    // 数据渲染
    dataDraw(area){
      let that = this;
      for(let i in that.pageData){
        if(that.pageData[area]){
          if(i == area){
            // 第一层数据
            that.areaData[0].num = that.pageData[i].private_venue + "平方米";
            that.areaData[1].num = that.pageData[i].public_venue + "平方米";
            that.areaData[2].num = that.pageData[i].indoor_venue + "平方米";
            that.areaData[3].num = that.pageData[i].funding + "元";
            that.areaData[4].num = that.pageData[i].runway_km + "公里";
            that.areaData[5].num = that.pageData[i].tennis_court + "片";
            that.areaData[6].num = that.pageData[i].snow_field + "片";
            that.areaData[7].num = that.pageData[i].consumption + "元";
            that.areaPie[0].data.value =  that.pageData[i].coverage;
            that.areaPie[1].data.value =  that.pageData[i].school;
            that.areaPie[2].data.value =  that.pageData[i].record;

            // 第二层数据
            that.numData[0].num = that.pageData[i].organization + "个";
            that.numData[1].num = that.pageData[i].register_number + "个";
            that.numChart[0].data.value = that.pageData[i].take_exercise;
            that.numChart[1].data.value = that.pageData[i].once_week;
            that.numChart[2].data.value = that.pageData[i].activity_number;
            that.numChart[3].data.value = that.pageData[i].per_year;
            that.numChart[4].data.value = that.pageData[i].public_server;
            that.numChart[5].data.value = that.pageData[i].active_week;
            that.numChart[6].data.value = that.pageData[i].gym;
            that.numChart[7].data.value = that.pageData[i].on_duty;

            // 第三层数据
            that.satisfactionData.num = that.pageData[i].satisfaction;
            that.healthChart[0].data.value = that.pageData[i].fitness_knowledge;
            that.healthChart[1].data.value = that.pageData[i].physique_testing;
            that.healthChart[2].data.value = that.pageData[i].exercise_standard;
            that.healthChart[3].data.value = that.pageData[i].primary_school;
            that.healthChart[4].data.value = that.pageData[i].shortsighted;
            that.healthChart[5].data.value = that.pageData[i].obesity;

            // 循环绘制第一层图表
            for(let i=0;i<that.areaPie.length;i++){
              // console.log(that.areaPie[i].id,that.areaPie[i].data)
              that.pie(that.areaPie[i].id,that.areaPie[i].data,['30%', '50%'],'0.16rem')
            }
            // 循环绘制第二层图表
            for(let i=0;i<that.numChart.length;i++){
              that.pie(that.numChart[i].id,that.numChart[i].data,['50%', '70%'],'0.10rem')
            }

            // 循环绘制第三层图表
            for(let i=0;i<that.healthChart.length;i++){
              that.pie(that.healthChart[i].id,that.healthChart[i].data,['30%', '50%'],'0.16rem')
            }
          }
        }else{
          that.areaData[0].num = 0 + "平方米";
          that.areaData[1].num = 0 + "平方米";
          that.areaData[2].num = 0 + "平方米";
          that.areaData[3].num = 0 + "元";
          that.areaData[4].num = 0 + "公里";
          that.areaData[5].num = 0 + "片";
          that.areaData[6].num = 0 + "片";
          that.areaData[7].num = 0 + "元";
          that.areaPie[0].data.value =  0;
          that.areaPie[1].data.value =  0;
          that.areaPie[2].data.value =  0;

          that.numData[0].num = 0 + "个";
          that.numData[1].num = 0 + "个";
          that.numChart[0].data.value = 0;
          that.numChart[1].data.value = 0;
          that.numChart[2].data.value = 0;
          that.numChart[3].data.value = 0;
          that.numChart[4].data.value = 0;
          that.numChart[5].data.value = 0;
          that.numChart[6].data.value = 0;
          that.numChart[7].data.value = 0;

          that.satisfactionData.num = 0;
          that.healthChart[0].data.value = 0;
          that.healthChart[1].data.value = 0;
          that.healthChart[2].data.value = 0;
          that.healthChart[3].data.value = 0;
          that.healthChart[4].data.value = 0;
          that.healthChart[5].data.value = 0;

          // 循环绘制第一层图表
          for(let i=0;i<that.areaPie.length;i++){
            // console.log(that.areaPie[i].id,that.areaPie[i].data)
            that.pie(that.areaPie[i].id,that.areaPie[i].data,['30%', '50%'],'0.16rem')
          }
          // 循环绘制第二层图表
          for(let i=0;i<that.numChart.length;i++){
            that.pie(that.numChart[i].id,that.numChart[i].data,['50%', '70%'],'0.10rem')
          }

          // 循环绘制第三层图表
          for(let i=0;i<that.healthChart.length;i++){
            that.pie(that.healthChart[i].id,that.healthChart[i].data,['30%', '50%'],'0.16rem')
          }

          that.toastShow = true;

          setTimeout(() => {
            that.toastShow = false;
          }, 2000);
        }
      }
    }
    // getPageData(type,id){
    //     let that = this;
    //     let url;
    //     if(type == "市") url = "/map/screen/getHomePeopleBuildDevelopment?city_id="+id;
    //     else url = "/map/screen/getHomePeopleBuildDevelopment?area_id="+id;
		// 		var data = {
		// 			token: window.getToken(),
		// 			app_id: window.appId,
		// 			time: timestamp(),
		// 		}
		// 		getAjax({
		// 			url: url,
		// 			method:"GET",
		// 			data:data,
		// 			success:function(res){
		// 				console.log(res)
    //         if(res.data.length > 0){
              
    //           let row1 = {
    //             graphic:[],chart:[]
    //           };
    //           let row2 = {
    //             graphic:[],chart:[]
    //           };
    //           let row3 = {
    //             graphic:[],chart:[]
    //           };
              
              
    //           res.data.forEach((item,index)=>{
    //               if(item.row == 1){
    //                 if(item.type == 1){
    //                     row1.graphic.push(item)
    //                 }else{
    //                     row1.chart.push(item)
    //                 }
    //               }else if(item.row == 2){
    //                 if(item.type == 1){
    //                     row2.graphic.push(item)
    //                 }else{
    //                     row2.chart.push(item)
    //                 }
    //               }else if(item.row == 3){
    //                 if(item.type == 1){
    //                     row3.graphic.push(item)
    //                 }else{
    //                     row3.chart.push(item)
    //                 }
    //               }
    //           })

    //           // 第一行数据赋值
    //           that.areaData = [];
    //           that.areaPie = [];
    //           if(row1.graphic.length > 0){
    //             row1.graphic.forEach((item,index)=>{
    //               that.areaData.push({
    //                 title:item.title,
    //                 num:item.value,
    //                 img:item.images,
    //               })
    //             })
    //           }
              
    //           if(row1.chart.length > 0){
    //             row1.chart.forEach((item,index)=>{
    //               that.areaPie.push({
    //                 id:"twoChart"+(index+1),
    //                 data:{
    //                   gender: item.title,
    //                   color1: '#42BF6C', //环形图数据达标部分颜色
    //                   color2: '#333453', //环形图背景色
    //                   color3: '#42BF6C', //环形图中间字体颜色
    //                   value: item.value,  
    //                 }
    //               })
    //             })
    //             // console.log(that.areaPie)
    //           }
              
              
    //           //  第二行数据赋值
    //           that.numData = [];
    //           that.numChart = [];
    //           if(row2.graphic.length > 0){
    //             row2.graphic.forEach((item,index)=>{
    //               that.numData.push({
    //                 title:item.title,
    //                 num:item.value,
    //                 img:item.images,
    //               })
    //             })
    //           }

    //           if(row2.chart.length > 0){
    //             row2.chart.forEach((item,index)=>{
    //               that.numChart.push({
    //                 id:"numChart"+(index+1),
    //                 data:{
    //                   gender: item.title,
    //                   color1: '#6AD4F9', //环形图数据达标部分颜色
    //                   color2: '#333453', //环形图背景色
    //                   color3: '#6AD4F9',
    //                   value: item.value,  
    //                 }
    //               })
    //             })
    //           }
              


    //           // 第三行赋值
    //           // that.satisfactionData = [];
    //           that.satisfactionData = {}
    //           if(row3.graphic.length > 0){
    //             that.satisfactionData.title = row3.graphic[0].title;
    //             that.satisfactionData.num = row3.graphic[0].value;
    //             that.satisfactionData.img = row3.graphic[0].images;
    //           }
              
    //           that.healthChart = [];
    //           if(row3.chart.length > 0){
    //             row3.chart.forEach((item,index)=>{
    //               that.healthChart.push({
    //                 id:"health"+(index+1),
    //                 data:{
    //                   gender: item.title,
    //                   color1: '#EED02B', //环形图数据达标部分颜色
    //                   color2: '#333453', //环形图背景色
    //                   color3: '#EED02B', //环形图中间字体颜色
    //                   value: item.value,  
    //                 }
    //               })
    //             })
    //           }
              


    //           // 循环绘制第二层图表
    //           for(let i=0;i<that.areaPie.length;i++){
    //             // console.log(that.areaPie[i].id,that.areaPie[i].data)
    //             that.pie(that.areaPie[i].id,that.areaPie[i].data,['30%', '50%'],'0.16rem')
    //           }
    //           // 循环绘制第三层图表
    //           for(let i=0;i<that.numChart.length;i++){
    //             that.pie(that.numChart[i].id,that.numChart[i].data,['50%', '70%'],'0.10rem')
    //           }

    //           // 循环绘制第四层图表
    //           for(let i=0;i<that.healthChart.length;i++){
    //             that.pie(that.healthChart[i].id,that.healthChart[i].data,['30%', '50%'],'0.16rem')
    //           }
            
    //         }else{
    //             that.toastShow = true;
    //             that.numData = [];
    //             that.numChart = [];
    //             that.areaData = [];
    //             that.areaPie = [];
    //             that.satisfactionData = {}
    //             that.healthChart = [];
    //             setTimeout(() => {
    //               that.toastShow = false;
    //             }, 2000);
    //         }
		// 			},
		// 		})

    // }
  },
  mounted() {
    let that = this;
    // let year = new Date().getYear()
    // console.log(year)
    that.getPageData();

    // let screenUserInfo = JSON.parse(window.localStorage.getItem("screenUserInfo"))
    // if(screenUserInfo.is_city==1){
    //   that.getPageData('市',330600);
    // }else if(screenUserInfo.is_city==0){
    //   that.getPageData('区',screenUserInfo.area_id);
    //   that.btns.filter((btn,index)=>{
    //     if(btn.id==screenUserInfo.area_id){
    //       that.currentIndex = index
    //     } 
    //   })
    // }
    // 绘制第二层图表
    // that.pie('twoHall',that.areaPie1,['30%', '50%'],'0.16rem');
    // that.pie('schoolOpen',that.areaPie2,['30%', '50%'],'0.16rem');
    // that.pie('numberOf',that.areaPie3,['30%', '50%'],'0.16rem');
    // 循环绘制第三层图表
    // for(let i=0;i<that.numChart.length;i++){
    //   that.pie(that.numChart[i].id,that.numChart[i].data,['50%', '70%'],'0.10rem')
    // }
    // // 循环绘制第四层图表
    // for(let i=0;i<that.healthChart.length;i++){
    //   that.pie(that.healthChart[i].id,that.healthChart[i].data,['30%', '50%'],'0.16rem')
    // }

  }
}
</script>

<style scoped>
.marginTop10{margin-top: 0.1rem;}
.font22{font-size: 0.22rem}
.greenFont{color: #42BF6C;}
.yellowFont{color: #EED02B;}
.lightGray{color: #CCCCCC;}
.container{
  position: relative;
}
.btnGroup{
  display: flex;
}
.btn{
  width: 1rem;
  height: 0.4rem;
  line-height: 0.4rem;
  text-align: center;
  color: white;
  background-color: #313965;
  border-radius: 0.1rem;
  margin-right: 0.25rem;
  cursor: pointer;
}
.btnFocus{
  width: 1rem;
  height: 0.4rem;
  line-height: 0.4rem;
  text-align: center;
  color: #001525;
  background-color: #20D7FE;
  border-radius: 0.1rem;
  margin-right: 0.25rem;
  cursor: pointer;
}
.rows{
  width: 18.11rem;
  height: 2.56rem;
  display: flex;
  margin-top: 0.2rem;
}


.dataOne{
  width: 10.6rem;
  display: flex;
  flex-wrap: wrap;
}
.dataOneItem{
  width: 22%;
  display: flex;
  align-items: center;
  margin-left: 0.3rem;
}
.dataOneImg{
  width: 0.49rem;
  height: 0.54rem;
  margin-right: 0.1rem;
}
.chartOne{
  display: flex;
  justify-content: space-around;
}
.chartOneItem{
  color: #CCCCCC;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding-top: 0.4rem;
}
.chartOneItem > p {
  height: 0.2rem;
}
.chartOneItem > div{
  width: 2.40rem;
  height: 2.40rem;
  margin-top: 0.2rem;
}


.dataTwo{
  width: 23%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.dataTwoItem{
  text-align: center;
}
.dataTwoImg{
  width: 0.63rem;
  height: 0.7rem;
  margin-bottom: 0.1rem;
}
.chartTwo{
  width: 73%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.chartTwoItem{
  width: 23%;
  display: flex;
  align-items: center;
  margin-top: 0.15rem;
}
.chartTwoItem > div{
  width: 1.30rem;
  height: 0.80rem;
}
.chartTwoItem > span{
  width: 2rem;
  margin-top: -0.1rem;
}


.dataThree{
  width: 16%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.dataThreeImg{
  width: 0.63rem;
  height: 0.7rem;
  margin-bottom: 0.1rem;
}
.chartThree{
  width: 80%;
  display: flex;
  justify-content: space-around;
}
.chartThreeItem{
  width: 16%;
  text-align: center;
  margin-top: 0.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp:2;
  -webkit-box-orient:vertical;
}
.chartThreeItem > p {
  height: 0.2rem;
}
.chartThreeItem >div{
  width: 2.41rem;
  height: 2.41rem;
}
.toast{
  width: 40%;
  line-height: 0.5rem;
  text-align: center;
  background: #19cff8;
  position: absolute;
  left: 30%;
  top: 30%;
  color: white;
  font-size: 0.4rem;
  border-radius: 0.2rem;
  padding: 0.2rem;
  z-index: 11;
}
.mask{
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  z-index: 10;
  cursor: pointer;
}
</style>